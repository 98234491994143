import { Box, Typography, useMediaQuery, useTheme, Stack } from '@mui/material'
import { DNGeneralOptions } from '@services/model/form/form.DN.model'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useState, useEffect, Fragment } from 'react'
import FormDialog from '@components/form/dialog_v2'
import DialogButton from '@components/button/DialogButton'
import FormDateTimePicker from '@components/form/dateTimePicker'
import FormTypography from '@components/form/typography'
import FormSelect from '@components/form/select'

// dialog field
const DialogFormField = (props: { children: any; fieldName: string }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Box
      sx={{
        display: 'flex',
        padding: 1,
        alignItems: 'center',
        gap: 1,
        ...(matchDownMd && {
          flexDirection: 'column',
          alignItems: 'flex-start',
        }),
      }}>
      <Box
        {...(!matchDownMd && {
          sx: {
            width: 200,
          },
        })}>
        <Typography>{t(props.fieldName)}:</Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'left',
          alignItems: 'center',
          width: '100%',
        }}>
        {props.children}
      </Box>
    </Box>
  )
}

interface BatchCreateDialogState {
  startOfNcPeroid: Date | undefined
  endOfNcPeroid: Date | undefined
  frequencyId: number | undefined
  frequency: number | undefined
  nonComplianceId: number | undefined
  noOfDnGenerated: number | undefined
}

// batch create dn dialog
const BatchCreateDNDialog = (props: {
  isOpen: boolean
  onClickOpen: (event: any) => void
  onGenerate: (state: BatchCreateDialogState) => void
  dnOptions: DNGeneralOptions | undefined
}) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const theme = useTheme()
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))
  const [dialogState, setDialogState] = useState<BatchCreateDialogState>({
    startOfNcPeroid: undefined,
    endOfNcPeroid: undefined,
    frequencyId: undefined,
    frequency: undefined,
    nonComplianceId: undefined,
    noOfDnGenerated: undefined,
  })

  const handleConfirmClick = () => {
    for (const property in dialogState) {
      if (dialogState[property] === undefined && property !== 'frequency') {
        enqueueSnackbar(t('Please fill in all the fields'), {
          variant: 'error',
          autoHideDuration: null,
        })
        return
      }
    }
    if (
      dialogState.startOfNcPeroid &&
      dialogState.endOfNcPeroid &&
      dialogState.startOfNcPeroid.getTime() > dialogState.endOfNcPeroid.getTime()
    ) {
      enqueueSnackbar(t('Start of NC Period must be earlier than end of NC Period'), {
        variant: 'error',
      })
      return
    }
    props.onGenerate(dialogState)
  }

  useEffect(() => {
    if (dialogState.startOfNcPeroid && dialogState.endOfNcPeroid && dialogState.frequencyId) {
      switch (dialogState.frequencyId) {
        case 1: {
          setDialogState({
            ...dialogState,
            noOfDnGenerated: Math.floor(
              (dialogState.endOfNcPeroid.getTime() - dialogState.startOfNcPeroid.getTime()) /
                (7 * 24 * 3600 * 1000),
            ),
            frequency: 7 * 24 * 3600 * 1000,
          })
          break
        }
        case 2: {
          setDialogState({
            ...dialogState,
            noOfDnGenerated: Math.floor(
              (dialogState.endOfNcPeroid.getTime() - dialogState.startOfNcPeroid.getTime()) /
                (24 * 3600 * 1000),
            ),
            frequency: 24 * 3600 * 1000,
          })
          break
        }
        case 3: {
          setDialogState({
            ...dialogState,
            noOfDnGenerated: Math.floor(
              (dialogState.endOfNcPeroid.getTime() - dialogState.startOfNcPeroid.getTime()) /
                (12 * 3600 * 1000),
            ),
            frequency: 12 * 3600 * 1000,
          })
          break
        }
        case 4: {
          setDialogState({
            ...dialogState,
            noOfDnGenerated: 1,
            frequency: undefined,
          })
          break
        }
      }
    }
  }, [dialogState.startOfNcPeroid, dialogState.endOfNcPeroid, dialogState.frequencyId])

  return (
    <FormDialog
      open={props.isOpen}
      onClose={props.onClickOpen}
      title={<Typography variant="h6">{t('Create Default Notice')}</Typography>}
      buttons={
        <Stack {...(!matchDownMd && { direction: 'row' })} spacing={2}>
          <DialogButton onClick={handleConfirmClick}>{t('Confirm')}</DialogButton>
          <DialogButton onClick={props.onClickOpen}>{t('Cancel')}</DialogButton>
        </Stack>
      }>
      <Box>
        <DialogFormField fieldName="Start of NC Period">
          <FormDateTimePicker
            value={dialogState.startOfNcPeroid}
            sx={{
              width: '100%',
            }}
            onChange={(date: Date) => {
              setDialogState({ ...dialogState, startOfNcPeroid: date })
            }}
          />
        </DialogFormField>
        <DialogFormField fieldName="End of NC Period">
          <FormDateTimePicker
            value={dialogState.endOfNcPeroid}
            sx={{
              width: '100%',
            }}
            onChange={(date: Date) => {
              setDialogState({ ...dialogState, endOfNcPeroid: date })
            }}
          />
        </DialogFormField>
        <DialogFormField fieldName="Frequency">
          <FormSelect
            value={dialogState.frequencyId}
            onChange={(selectedId: number) => {
              setDialogState({
                ...dialogState,
                frequencyId: selectedId === 0 ? undefined : selectedId,
              })
            }}
            options={[
              { key: 1, value: t('7 days') },
              { key: 2, value: t('24 hrs') },
              { key: 3, value: t('12 hrs') },
              { key: 4, value: t('N/A') },
            ]}
          />
        </DialogFormField>
        <DialogFormField fieldName="No. of DN will be Generated">
          <FormTypography value={dialogState.noOfDnGenerated} />
        </DialogFormField>
        <DialogFormField fieldName="Type of Non-Compliance">
          <FormSelect
            value={dialogState.nonComplianceId}
            onChange={(selectedId: number) => {
              setDialogState({
                ...dialogState,
                nonComplianceId: selectedId === 0 ? undefined : selectedId,
              })
            }}
            options={
              props.dnOptions
                ? props.dnOptions.typeOfNonComplianceList
                    ?.filter((nc) => nc.dnRefNo !== 'DN13' && nc.dnRefNo !== 'DN15')
                    ?.map((t) => ({
                      key: t.id,
                      value: `${t.dnRefNo} - ${t.typeOfNonCompliance}`,
                    }))
                : []
            }
          />
        </DialogFormField>
      </Box>
    </FormDialog>
  )
}

export default BatchCreateDNDialog
